// Customizable Area Start
import React from "react";

import {
  Avatar,
  Typography,
  Box,
  Drawer,
  CssBaseline,
  Stack
} from "@mui/material/";

import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {styled} from '@mui/material/styles';
import {icDashboard, icHome, icHealthWallet, icMyCases, icContactUs, icSetting} from './assets';

const Logo = styled('img')({
  width: '40px'
});

const NavItem = styled('img')({
  width: '80px',
  height:'49px'
});

interface StyledListItemIconProps {
  isActiveRoute?: boolean;
}

const StyledListItemIcon = styled(ListItemIcon)<StyledListItemIconProps>(({ isActiveRoute }) => ({
  justifyContent: "center",
  'img': {
    filter: isActiveRoute
      ? 'invert(46%) sepia(30%) saturate(2159%) hue-rotate(3deg) brightness(100%) contrast(95%)'
      : 'none', // Default (no filter)
  },
  '&:hover img': {
    filter: 'invert(46%) sepia(30%) saturate(2159%) hue-rotate(3deg) brightness(100%) contrast(95%)'
  },
  '&:active img': {
    filter: 'invert(46%) sepia(30%) saturate(2159%) hue-rotate(3deg) brightness(100%) contrast(95%)'
  },
}));

const StyledListItemButton = styled(ListItemButton)({
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const drawerWidth = 120;

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class Layout extends LandingPageController {
  constructor(props: Props) {
    super(props);
    this.handleDrawerTransitionEnd = this.handleDrawerTransitionEnd.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);

  }
  
  render(){
    const drawer = (
      <div style={{display:"flex",flexDirection:'column'}}>
        <Toolbar />
        <List sx={{justifyContent:'center'}}>
          <ListItem disablePadding onClick={()=>this.props.navigation.navigate("LandingPage")}>
            <StyledListItemButton sx={{marginBottom: '10px'}}>
              <StyledListItemIcon isActiveRoute={window.location.pathname.replace(/^\//, '') ==='LandingPage'? true : false}  >
                <NavItem src={icDashboard.default} />
              </StyledListItemIcon>
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={()=>this.props.navigation.navigate("CustomForm")}>
            <StyledListItemButton
              sx={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: '10px'
              }}
            >
              <StyledListItemIcon isActiveRoute={(window.location.pathname.replace(/^\//, '') === 'CustomForm' || window.location.pathname.replace(/^\//, '') === 'AddCase') ? true : false}>
                <NavItem src={icMyCases.default} />
              </StyledListItemIcon>
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemButton sx={{ marginBottom: '10px' }}>
              <StyledListItemIcon>
                <NavItem src={icHealthWallet.default} />
              </StyledListItemIcon>
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={() => this.navTo("Settings2")}>
            <StyledListItemButton sx={{ marginBottom: '10px' }}>
              <StyledListItemIcon>
                <NavItem src={icSetting.default} />
              </StyledListItemIcon>
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <StyledListItemButton sx={{ marginBottom: '10px' }}>
              <StyledListItemIcon>
                <NavItem src={icContactUs.default} />
              </StyledListItemIcon>
            </StyledListItemButton>
          </ListItem>
        </List>
        <Stack direction="column" sx={{alignItems:'center',marginTop:'20px'}}>
          <Avatar sx={{ cursor: 'pointer', backgroundColor: '#5DDB62'}} onClick={() => this.navTo("CustomisableUserProfiles")}>AQ</Avatar>
        </Stack>
      </div>
    );

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            borderBottom: "1px solid #ccc",
            height: "76px",
            backgroundColor: "#fff",
            boxShadow: "none",
            justifyContent:'flex-end',
            flexDirection:'row',
            display:'flex'
          }}
        >
          <Toolbar sx={{ display: { sm: 'none', md: 'none', width: '100%',justifyContent: 'space-between' } }}>
            <Stack sx={{ ml: 1, display: { width:'80px'}, paddingLeft: '25px' }}>
              <Logo src={icHome.default} />
            </Stack>

            <IconButton
              data-test-id = "idHambergerBar"
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              sx={{ mr: 1,color: '#0F172A' }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
            
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center', paddingRight: '25px', cursor: 'pointer', display: { xs: 'none',sm: 'flex' } }} onClick={() => this.navTo("CustomisableUserProfiles")}>
              <Avatar sx ={{backgroundColor:'#5DDB62'}}>AQ</Avatar>
              <Typography sx={{color:'black',paddingTop:'10px',fontSize:'14px'}}>Anh Quoc</Typography>
          </Stack>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            data-test-id="handleDrawerClose"
            open={this.state.mobileOpen}
            onTransitionEnd={this.handleDrawerTransitionEnd}
            onClose={this.handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <Stack sx={{alignItems:'center',marginTop:'20px'}}> 
              <Logo src={icHome.default} />
            </Stack>

            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            
            <Stack sx={{ alignItems: 'center', marginTop: '20px' }}>  
              <Logo src={icHome.default} />
            </Stack>
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: 0,
            py: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          {this.props.children}
        </Box>
      </Box>
    );
  }
}

// Customizable Area End
