import React from 'react';
import {
    Typography,
    Box,
    styled,
    Stack,
    TextField
} from "@mui/material";

interface Props {
    label: string;
    value: string;
    placeholder: string;
    name: string;
    testId: string;
    error: boolean;
    handleInput: any;
    errorTitle: string;
    inputProps?: any;
    InputProps?: any;
}

const InputBase = ({ value, error, handleInput, errorTitle, testId, name, placeholder, inputProps, InputProps, label }: Props) => {
    return (
        <Stack gap={"4px"}>
            <Typography className="infoBox-title" style={styles.label} >{label}</Typography>
            <Input
                data-test-id={testId}
                value={value}
                error={error}
                name={name}
                inputProps={inputProps}
                InputProps={InputProps}
                placeholder={placeholder} variant="outlined" fullWidth margin="normal" required
                onChange={(e) => handleInput(e.target.name, e.target.value)} />
            {error ?
                <Box sx={styles.errorMsg}>{errorTitle}</Box> : null}
        </Stack>
    )
}

const Input = styled(TextField)({
    border: "none",
    height: '56px',
    "& div": {
        borderRadius: '8px',
        padding: "8px",
        height: "100%"
    },
    input: {
        color: "#94A3B8",
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: "Inter",
    }
})

const styles = {
    errorMsg: {
        color: '#DC2626',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: "Inter"
    },
    label: {
        fontSize: '14px'
    },
    phoneAdornment: {
        fontSize: '16px',
        fontWeight: '400',
        color: '#94A3B8',
        fontFamily: "Inter"
    },
}

export default InputBase