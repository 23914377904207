import React from "react";

// Customizable Area Start
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { styled } from '@mui/material/styles';
import { StyleSheet } from "react-native";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  }));
  BpCheckedIcon = styled(this.BpIcon)({
    backgroundColor: '#F59E0B',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  });

  StyledImg = styled('img')(({ theme }) => ({
    width: '100%',
    height: '100%',
    marginTop: '42px',
    marginBottom: '40px',
    [theme.breakpoints.up('md')]: {
      height: '73%',
      borderRadius: '64px 0px 0px 64px',
    },
  }));
  styless = {
    arrow: {
      color: '#0F172A'
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      height: "26px",
      width: '71px',
      marginTop: '58px',
      marginLeft: '34px'
    },
    backBtn: {
      fontSize: '18px',
      color: '#0F172A',
      fontFamily:"Inter"
    },
    headImg: {
      height: '80px',
      width: '70px',
      marginRight: '30px'
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: { xs: '50px', md: '70px', lg: '105px' },
      marginTop: '50px',
      marginBottom: '20px'
    },
    headContent: {
      paddingTop: "15px"
    },
    head2: {
      fontSize: '20px',
      marginRight: '5px',
      fontWeight: '400',
      fontFamily:"Inter"
    },
    head1: {
      fontSize: '30px',
      fontWeight: '400',
      fontFamily:"Inter"
    },
    head3: {
      fontSize: '20px',
      fontWeight: '700',
      fontFamily:"Inter"
    },
    inputLable: {
      fontSize: '14px',
      display: 'flex',
      fontWeight: '700'
    },
    inputContainer: {
      width: '100%',
      padding: { xs: '0 50px 0 50px', md: '0 70px 0 70px', lg: '0 105px 0 105px' }
    },
    required: {
      color: 'red',
      marginLeft: '3px'
    },
    lastnameInput: {
      height: '56px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '400'
    },
    firstnameInput: {
      height: '56px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '400'
    },
    emailInput: {
      height: '56px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '400'
    },
    passwordInput: {
      height: '56px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '400'
    },
    phoneInput: {
      height: '56px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '400'
    },
    errorMsg: {
      color: '#DC2626',
      fontSize: '12px',
      fontWeight: '400'
    },
    confirmpassInupt: {
      height: '56px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '400'
    },
    terms: {
      display: 'flex',
      flexWrap: 'wrap',
      width: "100%",
      justifyContent: 'center',
      fontSize: '13px',
      fontWeight: '700'
    },
    terms2: {
      margin: "0 4px 0 4px",
      color: '#D97706'
    },
    signup: {
      width: '100%',
      height: '56px',
      fontSize: '15px',
      background: 'linear-gradient(to right, #FCD34D, #D97706 )',
      marginTop: '8px',
      textTransform: 'none' as 'none'
    },
    haveAcc: {
      width: '100%',
      fontSize: '16px',
      fontWeight: '700',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '34px 0 34px 0'
    },
    signin: {
      color: '#D97706',
      marginLeft: '4px'
    },
    passwordFormat: {
      fontSize: '12px',
      fontWeight: "400"
    },
    forget: {
      width: '100%',
      height: '35px',
      color: '#D97706',
      fontSize: '15px',
      background: 'none',
      marginTop: '8px',
      textTransform: 'none' as 'none'
    },
    mainBox: {
      paddingTop: '10%',
      display: 'flex',
      flexDirection: "column",
      "@media only screen and (max-width: 1200px)": {
        height: "800px",
      },
    },
    Choose: {
      fontSize: '24px',
      fontWeight: '400',
      marginTop: "40px"
    },
    PHC: {
      fontSize: '18px',
      fontWeight: '400',
    },
    membership: {
      color: '#57534E',
      marginLeft: '10px',
      fontSize: '14px',
      fontWeight: '400',
      display: 'flex',
      flexWrap: 'wrap'
    },
    Boxalign: {
      display: 'flex',
      alignItems: 'center',
    },
    boxGap: {
      marginTop: "8px"
    },
    boxGap2: {
      marginBottom: "8px"
    },
    boxGap3: {
      marginBottom: "40px"
    },
    errMsg: {
      fontSize: "12px",
      fontWeight: "400",
      color:"#DC2626"
    },
    FontFam:{
     fontFamily:"Inter"
    }
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        {this.state.signin ? (
          <Grid container style={this.styless.FontFam}>
            <Grid item xs={12} lg={6} sx={this.styless.mainBox}>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={this.styless.form}>
                    <div style={this.styless.headImg}>
                      <img src={this.state.head}/>
                    </div>
                    <div style={this.styless.headContent}>
                      <Typography variant="h4" component="h1" style={this.styless.head1}>
                        Welcome
                      </Typography>
                      <div style={{ display: 'flex' }}>
                        <Typography variant="h5" component="h2" style={this.styless.head2}>
                          To
                        </Typography>
                        <Typography variant="h5" component="h2" style={this.styless.head3}>
                          Advantage PHC
                        </Typography>
                      </div>
                    </div>
                  </Box>
                  <Grid container spacing={2} sx={this.styless.inputContainer}>
                    <Grid item xs={12}>
                      <div>
                        <div style={this.styless.inputLable}>Email address <Box style={this.styless.required}>*</Box></div>
                        <TextField
                          data-test-id='email'
                          name='email address'
                          error={this.state.inputErrors.emailError} 
                          placeholder="Enter email address" variant="outlined" fullWidth margin="normal" required style={this.styless.emailInput}
                          onChange={(e: any) => this.handleInput(e)}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <div style={this.styless.inputLable}>Password<Box style={this.styless.required}>*</Box></div>
                        <TextField
                          data-test-id='password'
                          name='password'
                          error={this.state.inputErrors.passwordError}
                          placeholder="Enter password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          variant="outlined" fullWidth margin="normal" required style={this.styless.phoneInput}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={this.handleClickShowPassword}
                                  data-test-id='showPassword'
                                >
                                  {this.state.showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e: any) => this.handleInput(e)}
                        />
                      </div>
                      {this.state.inputErrors.emailError || this.state.inputErrors.passwordError ?
                        <Box sx={this.styless.errMsg}>Incorrect login credentials, please try again</Box> : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        data-test-id="btnForgotPassword"
                        style={this.styless.forget} 
                        onClick={this.navigateToForgetPassword}
                        >
                        Forgot password?
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        data-test-id="signInButton"
                        variant="contained" 
                        style={this.styless.signup}
                        onClick={this.signin}
                      >
                        Sign in
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={this.styless.haveAcc}>
                      Don’t have account?
                        <Box style={this.styless.signin}
                          onClick={this.signup} data-test-id="btnSocialLogin"
                        >Sign up</Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <this.StyledImg src={this.state.sidePic} />
            </Grid>
          </Grid>
        ) : (
          <Grid container style={this.styless.FontFam}>
            <Grid item xs={12} lg={6}>
              <div style={this.styless.root}
                onClick={this.signup}
                data-test-id="bckBtn"
              >
                <ArrowBackIosIcon style={this.styless.arrow} />
                <div style={this.styless.backBtn}>Back</div>
              </div>
              <Box sx={this.styless.mainBox}>
                <Grid container>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={this.styless.form}>
                      <div style={this.styless.headImg}>
                        <img src={this.state.head} />
                      </div>
                      <div style={this.styless.headContent}>
                        <Typography variant="h4" component="h1" style={this.styless.head1}>
                          Welcome
                        </Typography>
                        <div style={{ display: 'flex' }}>
                          <Typography variant="h5" component="h2" style={this.styless.head2}>
                            To
                          </Typography>
                          <Typography variant="h5" component="h2" style={this.styless.head3}>
                            Advantage PHC
                          </Typography>
                        </div>
                      </div>
                    </Box>
                    <Grid container spacing={2} sx={this.styless.inputContainer}>
                      <Grid item xs={12} sx={this.styless.Choose}>
                        Choose
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container >
                          <Grid item xs={12} sx={this.styless.boxGap}>
                            <Box sx={this.styless.Boxalign}>
                              <Radio
                                disableRipple
                                color="default"
                                data-test-id="newMember"
                                checkedIcon={<this.BpCheckedIcon />}
                                icon={<this.BpIcon />}
                                checked={this.state.newMember}
                                onClick={this.handlemember}
                              />
                              <Box sx={this.styless.PHC}>I'm a new Advantage PHC client</Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sx={this.styless.boxGap2}>
                            <Box sx={this.styless.membership}>Create a new Advantage PHC account and pay for membership</Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={this.styless.boxGap3}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box sx={this.styless.Boxalign}>
                              <Radio
                                disableRipple
                                color="default"
                                data-test-id="existingMember"
                                checkedIcon={<this.BpCheckedIcon />}
                                icon={<this.BpIcon />}
                                checked={this.state.existingMember}
                                onClick={this.handlemember}
                              />
                              <Box sx={this.styless.PHC}>I'm an existing Advantage PHC client</Box>
                            </Box>
                          </Grid>
                          <Grid item xs={8}>
                            <Box sx={this.styless.membership}>Retrieve your account information if you're already a paying Advantage PHC client</Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          data-test-id="continueButton"
                          variant="contained" style={this.styless.signup}
                          onClick={this.goTo}
                        >
                          Continue
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid >
            <Grid item xs={12} lg={6}>
              <this.StyledImg src={this.state.sidePic} />
            </Grid>
          </Grid>
        )}
      </>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
