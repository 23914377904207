// Customizable Area Start
import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material/styles';
import {Grid,Typography,TextField,Box,Button,InputAdornment} from '@mui/material';



// Customizable Area End

import ForgotPasswordController, {
    Props,
  configJSON,
} from "./ForgotPasswordController";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
      return (
        // Customizable Area Start
        <Grid container>
            <Grid item xs={12} lg={6}>
                <Grid container style={{ height: '100%' }}>
                    <Grid item xs={12}>
                        <div
                            data-test-id="goBackButton"
                            style={styles.rootForgotPassword}
                            onClick={() => this.goToHomeWeb()} >

                            <ArrowBackIosIcon style={styles.arrowForgotPassword} />
                            <div style={styles.backBtnForgotPassword}>Back</div>
                        </div>
                        <Box sx={styles.formForgotPassword}>
                            <Grid container sx={styles.inputContainer}>
                                <div style={{ width: '100%' }}>
                                    <div style={styles.headImgForgotPassword}>
                                        {<img src={this.state.logo} />}
                                    </div>
                                </div>
                                <Box style={styles.containerLabelInputNumber}>
                                    <Typography style={styles.head3}>
                                        Please enter registered mobile number
                                    </Typography>
                                </Box>

                                <Grid item xs={12}>
                                    <div>
                                        <div style={styles.inputLable}>Contact number<Box style={styles.required}>*</Box></div>
                                        <TextField
                                            data-test-id="phoneNumber"
                                            InputProps={{
                                            startAdornment: (
                                                <InputAdornment style={styles.phoneAdornment} position="start">
                                                  +1
                                                </InputAdornment>
                                              ),
                                            }}
                                            error={this.state.phoneErrorMessage!==""}
                                            name='phone number'
                                            placeholder="000 000 0000"
                                            variant="outlined"
                                            value={this.state.phone}
                                            fullWidth margin="normal"
                                            required style={styles.phoneInput}
                                            onChange={(e: any) => this.handleInput(e)}

                                        />
                                        {this.state.phoneErrorMessage &&
                                            <Box
                                                data-test-id="errorMessageValidate"
                                                sx={styles.containerErrorRegisteredUser}>
                                                    <Typography style={styles.errorMsg}>
                                                        {this.state.phoneErrorMessage}
                                                    </Typography>
                                            </Box>}
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        data-test-id="continue-btn"
                                        variant={"outlined"}
                                        sx={{ textTransform: 'capitalize', fontFamily:"Inter" }}
                                        style={styles.continueBtn}
                                        onClick={() => this.goToOtpVerifyAfterAuthCheck({ mobile_otp: this.state.phone })} >
                                        Continue
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box >

                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
                <StyledImgForgotPassword src={this.state.sidePic} />
            </Grid>
        </Grid>
        // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledImgForgotPassword = styled('img')(({ theme }) => ({
    width: '100%',
    height: '100%',
    marginTop: '42px',
    marginBottom: '40px',
    [theme.breakpoints.up('md')]: {
        height: '73%',
        borderRadius: '64px 0px 0px 64px',
    },
}));
const styles = {
    containerErrorRegisteredUser:{
        width:"100%",
        display:'flex',
        justifyContent:"center"
    },
    containerLabelInputNumber:{
        display:'flex',
        width:'100%',
        justifyContent: 'center',
    },
    rootForgotPassword : {
        display: 'flex',
        alignItems: 'center',
        height: "26px",
        width: '71px',
        marginTop: '58px',
        marginLeft: '34px',
    },
    arrowForgotPassword : {
        color: '#0F172A'
    },
    backBtnForgotPassword : {
        fontSize: '18px',
        color: '#0F172A',
        fontFamily:"Inter"
    },
    formForgotPassword : {
        heigth:'100%',
        display: 'flex',
        flexWrap:'wrap',
        marginLeft: {xs:'50px', md:'70px', lg:'105px'},
        marginTop: '200px',
        marginBottom: '20px',
        
    },
    headImgBoxForgotPassword:{
      with: '100%',
      heigth: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
    },
    headImgForgotPassword : {
        margin:"0 auto",
        height: '80px',
        width: '70px',
    },
    head3 : {
        margin:'20px 0',
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "28px",
    },
    inputContainer : {
        heigth: '100%',
        width: '100%',
        padding: {xs:'0 50px 0 0px', md:'0 70px 0 0px', lg:'0 105px 0 0px'},
    },
    inputLable : {
        fontSize: '14px',
        display: 'flex',
        fontWeight:'700',
        fontFamily: "Inter",
        lineHeight: "22px",
    },
    required : {
        color: 'red',
        marginLeft: '3px'
    },
    phoneInput : {
        height: '56px',
        borderRadius: '8px',
        fontSize:'16px',
        fontWeight:'400'
    },
    errorMsg : {
        color:'#DC2626',
        fontSize:'14px',
        lineheigth: '22px',
        fontWeight:'400',
        align : 'center'
    },
    continueBtn : {
        width: '100%',
        height: '56px',
        fontSize: '15px',
        color: 'white',
        background: 'linear-gradient(to right, #FCD34D, #D97706 )',
        marginTop: '8px',
        border: 'none',
    },
    continueBtnBlock : {
        width: '100%',
        height: '56px',
        fontSize: '15px',
        background: '#F1F5F9',
        marginTop: '8px',
        border: 'none',
        color:'#64748B',
    },
    phoneAdornment: {
        fontSize:'16px',
        fontWeight:'400',
        color: '#0F172A'
    },

};


// Customizable Area End
