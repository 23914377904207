// Customizable Area Start
import React from "react";
import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import ClearIcon from '@mui/icons-material/Clear';
import { color } from "react-native-elements/dist/helpers";


const TCPolicyStyledModal = styled(Modal)({
    '& .modelstyle': {
        borderRadius: 12,
    },
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Grid container style={styles.FontFam}>
                <Grid item xs={12} lg={6}>
                    <Grid container>
                        <Grid item xs={12}>
                                <div style={styles.root} onClick={()=>this.phonenumberVerification('Home')} >
                                    <ArrowBackIosIcon style={styles.arrow} />
                                    <div style={styles.backBtn}>Back</div>
                                </div>
                            <Box sx={styles.form}>
                                <div style={styles.headImg}>
                                    <img src={this.state.head} />
                                </div>
                                <div style={styles.headContent}>
                                    <Typography variant="h4" component="h1" style={styles.head1}>
                                        Welcome
                                    </Typography>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="h5" component="h2" style={styles.head2}>
                                            To
                                        </Typography>
                                        <Typography variant="h5" component="h2" style={styles.head3}>
                                            Advantage PHC
                                        </Typography>
                                    </div>
                                </div>
                            </Box>
                            <Grid container spacing={2} sx={styles.inputContainer}>
                                <Grid item xs={12} md={6}>
                                    <div>
                                        <div style={styles.inputLable}>First name <Box style={styles.required}>*</Box></div>
                                        <TextField
                                        data-test-id='firstName'
                                        error = {this.state.inputErrors.firstNameError}
                                        name='first name'
                                        inputProps={{ maxLength: 64 }}
                                         placeholder="Enter first name" variant="outlined" fullWidth margin="normal" required style={styles.firstnameInput}
                                        onChange={ (e:any)=>this.handleInput(e)
                                         } />
                                         {this.state.inputErrors.firstNameError ? 
                                        <Box sx={styles.errorMsg}>Please enter a valid first name</Box> : null}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div>
                                        <div style={styles.inputLable}>Last name <Box style={styles.required}>*</Box></div>
                                        <TextField 
                                        data-test-id='lastName'
                                        error = {this.state.inputErrors.lastNameError}
                                        name='last name'
                                        inputProps={{ maxLength: 64 }}
                                        placeholder="Enter last name" variant="outlined" fullWidth margin="normal" required style={styles.lastnameInput}
                                        onChange={ (e:any)=>this.handleInput(e) } />
                                        {this.state.inputErrors.lastNameError ? 
                                        <Box sx={styles.errorMsg}>Please enter a valid last name</Box> : null}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <div style={styles.inputLable}>Email address <Box style={styles.required}>*</Box></div>
                                        <TextField 
                                        data-test-id='email'
                                        inputProps={{ maxLength: 64 }}
                                        error = {this.state.inputErrors.emailError}
                                        name='email address'
                                        placeholder="Enter email address" variant="outlined" fullWidth margin="normal" required style={styles.emailInput} 
                                        onChange={ (e:any)=>this.handleInput(e) }/>
                                        {this.state.inputErrors.emailError ? 
                                        <Box sx={styles.errorMsg}>Please enter a valid email address</Box> : null}
                                         {this.state.emailInvalidError !== "" ? 
                                        <Box sx={styles.errorMsg}>Email invalid</Box> : null}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <div style={styles.inputLable}>Phone number <Box style={styles.required}>*</Box></div>
                                        <TextField
                                        data-test-id='phoneNumber'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment style={styles.phoneAdornment} position="start">
                                                  +1
                                                </InputAdornment>
                                              ),
                                        }}
                                        error = {this.state.inputErrors.phoneError}
                                        name='phone number'
                                        placeholder="000 000 0000" variant="outlined" fullWidth margin="normal" required style={styles.phoneInput}
                                        onChange={ (e:any)=>this.handleInput(e) } />
                                        {this.state.inputErrors.phoneError ? 
                                        <Box sx={styles.errorMsg}>Please enter a valid US phone number</Box> : null}
                                        {this.state.phoneNumberAlreadyTakenError !== "" ? 
                                        <Box sx={styles.errorMsg}>This phone number is already taken</Box> : null}
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <div style={styles.inputLable}>Password <Box style={styles.required}>*</Box></div>
                                        <TextField 
                                        data-test-id='password'
                                        error = {this.state.inputErrors.passwordError}
                                        name='password'
                                        placeholder="Enter password" 
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        variant="outlined" fullWidth margin="normal" required style={styles.phoneInput} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.handleClickShowPassword}
                                                    >
                                                        {this.state.showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} 
                                        onChange={ (e:any)=>this.handleInput(e) }/>
                                        {this.state.inputErrors.passwordError && 
                                           <Box sx={styles.errorMsg}>{this.state.errorMessages.passwordErrorMessage}</Box>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textSecondary" style={styles.passwordFormat} paragraph>
                                        1. At least one capital letter<br />
                                        2. At least one lowercase letter<br />
                                        3. At least one number<br />
                                        4. At least one special character like $, @, #<br />
                                        5. Minimum character length is 8 characters<br />
                                        6. Password has to be different from previously used one
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div>
                                        <div style={styles.inputLable}>Confirm password <Box style={styles.required}>*</Box></div>
                                        <TextField
                                        data-test-id='confirmPassword'
                                        error = {this.state.inputErrors.confirmPasswordError}
                                        name='confirm password'
                                        placeholder="Confirm password" 
                                        type={this.state.showConfirmPassword ? 'text' : 'password'}variant="outlined" fullWidth margin="normal" required style={styles.confirmpassInupt}
                                        InputProps={{ 
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.handleconfirmPassword}
                                                    >
                                                        {this.state.showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={ (e:any)=>this.handleInput(e) } />
                                        {this.state.inputErrors.confirmPasswordError && 
                                          <Box sx={styles.errorMsg}>{this.state.errorMessages.confirmPasswordErrorMessage}</Box>
                                        }
                                    </div>
                                </Grid> 
                                <Grid item xs={12}>
                                    <Typography sx={styles.terms}>
                                        By signing up you agree to our 
                                        <Box style={styles.terms2} data-test-id="handleOpenTermConditionId" onClick={this.handleOpenTermCondition}>Terms and conditions</Box>
                                        and
                                        <Box style={styles.terms2} data-test-id="handleOpenPolicyId" onClick={this.handleOpenPolicy}>Privacy policy</Box>
                                    </Typography>
                                    <>
                                        <TCPolicyStyledModal
                                            open={this.state.isOpenTC}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box className="modelstyle" sx={TermPolicyStyles.modalStyle}>
                                                <Box sx={TermPolicyStyles.boxText}>
                                                    <div></div>
                                                    <Typography
                                                        id="modal-modal-title"
                                                        variant="h6"
                                                        component="h2"
                                                        sx={TermPolicyStyles.conentStyle}
                                                    >
                                                        Terms and conditions
                                                    </Typography>
                                                    <ClearIcon
                                                        data-test-id="handleCloseTermConditionId"
                                                        sx={TermPolicyStyles.icon}
                                                        onClick={this.handleCloseTermCondition}
                                                    />
                                                </Box>

                                                <Box sx={TermPolicyStyles.modalContentStyle}>
                                                    <Box sx={TermPolicyStyles.termsContent}>
                                                        {this.state.TermsAndCondList &&
                                                            this.state.TermsAndCondList.map((heading) => {
                                                                return (
                                                                    <Box sx={{ marginBottom: '18px' }}> 
                                                                        <Typography sx={TermPolicyStyles.heading}>{heading.title}</Typography>
                                                                        <Typography
                                                                            sx={TermPolicyStyles.text}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: heading.description,
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                );
                                                            })}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </TCPolicyStyledModal>
                                    </>
                                    <>
                                        <TCPolicyStyledModal
                                            open={this.state.isOpenPolicy}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box className="modelstyle" sx={TermPolicyStyles.modalStyle}>
                                                <Box sx={TermPolicyStyles.boxText}>
                                                    <div></div>
                                                    <Typography
                                                        id="modal-modal-title"
                                                        variant="h6"
                                                        component="h2"
                                                        sx={TermPolicyStyles.conentStyle}
                                                    >
                                                        Privacy Policy
                                                    </Typography>
                                                    <ClearIcon
                                                        data-test-id="handleClosePolicyId"
                                                        sx={TermPolicyStyles.icon}
                                                        onClick={this.handleClosePolicy}
                                                    />
                                                </Box>

                                                <Box sx={TermPolicyStyles.modalContentStyle}>
                                                    <Box sx={TermPolicyStyles.termsContent}>
                                                        {this.state.PrivacyPolicyList &&
                                                            this.state.PrivacyPolicyList.map((heading) => {
                                                                return (
                                                                    <Box sx={{marginBottom:'18px'}}>
                                                                        <Typography sx={TermPolicyStyles.heading}>{heading.title}</Typography>
                                                                        <Typography
                                                                            sx={TermPolicyStyles.text}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: heading.description,
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                );
                                                            })}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </TCPolicyStyledModal>
                                    </>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                    data-test-id="signUpButton"
                                     variant="contained" style={styles.signup} onClick={this.handleSignUp} >
                                        Sign up
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.haveAcc}> 
                                        Already have an account? 
                                        <Box style={styles.signin} onClick={()=>this.phonenumberVerification('EmailAccountLoginBlock')}>Sign in</Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <StyledImg src={this.state.sidePic} />
                </Grid>
            </Grid>
        )
    }
}

const StyledImg = styled('img')(({ theme }) => ({
    width: '100%',
    height: '100%',
    marginTop: '42px',
    marginBottom: '40px',
    objectFit:'cover',
    [theme.breakpoints.up('md')]: {
        height: '73%',
        borderRadius: '64px 0px 0px 64px',
    },
}));
const styles = {
    root : {
        display: 'flex',
        alignItems: 'center',
        height: "26px",
        width: '71px',
        marginTop: '58px',
        marginLeft: '34px'
    },
    arrow : {
        color: '#0F172A'
    },
    backBtn : {
        fontSize: '18px',
        color: '#0F172A',
        fontFamily:"Inter"
    },
    form : {
        display: 'flex',
        flexWrap:'wrap',
        marginLeft: {xs:'50px', md:'70px', lg:'105px'},
        marginTop: '50px',
        marginBottom: '20px'
    },
    headImg : {
        height: '80px',
        width: '70px',
        marginRight:'30px'
    },
    headContent : {
        paddingTop: "15px"
    }, 
    head1 : {
        fontSize: '30px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    head2 : {
        fontSize: '20px',
        marginRight: '5px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    head3 : {
        fontSize: '20px',
        fontWeight:'700',
        fontFamily:"Inter"
    },
    inputContainer : {
        width: '100%',
        padding: {xs:'0 50px 0 50px', md:'0 70px 0 70px', lg:'0 105px 0 105px'} 
    },
    inputLable : {
        fontSize: '14px',
        display: 'flex',
        fontWeight:'700',
        fontFamily:"Inter",
        color:"#64748B"
    },
    required : {
        color: 'red',
        marginLeft: '3px'
    },
    firstnameInput : {
        height: '56px',
        borderRadius: '8px',
        fontSize:'16px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    lastnameInput : {
        height: '56px',
        borderRadius: '8px',
        fontSize:'16px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    emailInput : {
        height: '56px',
        borderRadius: '8px',
        fontSize:'16px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    phoneInput : {
        height: '56px',
        borderRadius: '8px',
        fontSize:'16px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    phoneAdornment: {
        fontSize:'16px',
        fontWeight:'400',
        color: '#0F172A'
    },
    passwordInput : {
        height: '56px',
        borderRadius: '8px',
        fontSize:'16px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    confirmpassInupt : {
        height: '56px',
        borderRadius: '8px',
        fontSize:'16px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    errorMsg : {
        color:'#DC2626',
        fontSize:'12px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    terms : {
        display: 'flex',
        flexWrap:'wrap',
        width: "100%",
        justifyContent: 'center',
        fontSize: '13px',
        fontWeight:'700',
        fontFamily: "Inter"
    },
    terms2 : {
        margin: "0 4px 0 4px",
        color: '#D97706'
    },
    signup : {
        width: '100%',
        height: '56px',
        fontSize: '15px',
        background: 'linear-gradient(to right, #FCD34D, #D97706 )',
        marginTop: '8px'
    },
    haveAcc : {
        width: '100%',
        fontSize: '16px',
        fontWeight:'700',
        fontFamily:"Inter",
        display: 'flex',
        flexWrap:'wrap',
        justifyContent: 'center',
        margin: '34px 0 34px 0'
    },
    signin : {
        color: '#D97706',
        marginLeft: '4px'
    },
    passwordFormat : {
        fontSize: '12px',
        fontWeight:"400",
        fontFamily:"Inter"
    },
    FontFam:{
        fontFamily:"Inter"
    }
};

const TermPolicyStyles = {
    container: {
        flex: 1,
        margin: 20,
    },
    boxText: {
        display: "flex",
        justifyContent: "space-between",
        paddingInline:'20px'

    },
    conentStyle: {
        color: '#D97706',
        fontFamily:"Inter",
        fontSize: '18.3px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24.4px',
        textTransform: 'uppercase'

    },
    termsContent: {
        marginBottom: "20px",
        overflowY: "auto",
        maxHeight:"80vh"
    },
    text: {
        color: '#1C1917',
        fontSize: '9.766px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '14.649px'
    },
    heading: {
        color: '#1C1917',
        fontSize: '14.649px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '19.533px',
        letterSpacing: '-0.073px',
    },
    modalStyle: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: '652px',
        height: '540px',
        bgcolor: 'background.paper',
        borderRadius: 12,
        paddingTop: 4,
        "@media only screen and (min-width: 1920px) and (max-width: 1920px) and (min-height: 1080px) and (max-height: 1080px)": {
            width: '651.292px',
            height: '749.566px',
        },
        "@media only screen and (min-width: 1440px) and (max-width: 1440px) and (min-height: 983px) and (max-height: 983px)": {
            top: "28%",
            left: "28%",
            transform: "translate(-8%, -28%)",
            width: '651.292px',
            height: '749.566px',
        },
        "@media screen and (max-width: 480px)": {
            width:'100%'
        },
        flexShrink: 0,
    },
    modalContentStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: "500px",
        "@media only screen and (min-width: 1920px) and (max-width: 1920px) and (min-height: 1080px) and (max-height: 1080px)": {
            height: '640px'
        },
        "@media only screen and (min-width: 1440px) and (max-width: 1440px) and (min-height: 983px) and (max-height: 983px)": {
            height: '640px'
        },
        paddingTop: "25px",
        paddingRight: "10px",
        pl: 8,
        overflowY: "auto"
    },
    spinnerContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        flex: 1,
        padding: 20,
        fontWeight: "600",
        fontSize: 22,
    },
    icon: {
       
    },
    termsCondsTitle: {
        fontWeight: "600",
        marginBottom: 20,
        fontSize: 22,
    },
    scrollView: {
        height: "100%",
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "auto",
        marginBottom: 40,
        marginRight: 20,
    },
    termsCondsContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomWidth: 1,
        padding: 20,
    },
    button: {
        backgroundColor: "blue",
        marginLeft: 10,
        width: 120,
        height: 40,
        display: "flex",
        justifyContent: "center",
        borderRadius: 4,
        alignSelf: "flex-end",
    },
    buttonLabel: {
        fontSize: 16,
        color: "#fff",
        textAlign: "center",
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        margin: 20,
    },
};

// Customizable Area End