// Customizable Area Start
import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { styled } from '@mui/material/styles';

// Customizable Area End

import ForgotPasswordController, {
    Props,
  configJSON,
} from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Grid container>
            <Grid item xs={12} lg={6}>

                <div
                    style={styles.rootNewPassword}
                    onClick={() => this.goTo('ForgotPasswordOTPWeb')}
                    data-test-id='btnContainerBack'
                >
                    <ArrowBackIosIcon
                        style={styles.arrowNewPassword}
                        data-test-id='iconBack'
                    />
                    <div style={styles.backBtnNewPassword}>Back</div>
                </div>

                <Grid sx={styles.inputContainer}>
                    <div style={{ width: '100%' }}>
                        <div style={styles.headImgNewPassword}>
                            {<img src={this.state.logo} />}
                        </div>
                    </div>
                    <Typography style={{ textAlign: 'center', margin: '20px 0', fontSize: '25px', fontWeight: '800' }}>
                        Reset password
                    </Typography>
                    <Grid item xs={12}>
                        <div>
                            <div style={styles.inputLable}>Password <Box style={styles.required}>*</Box></div>
                            <TextField
                                data-test-id='password'
                                error={this.state.inputErrors.passwordError}
                                name='password'
                                placeholder="Enter password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                variant="outlined" fullWidth margin="normal" required style={styles.passwordInput}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                data-test-id='showPasswordIcon'
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e: any) => this.handleInput(e)} />
                            {this.state.inputErrors.passwordError ?
                                <Box sx={styles.errorMsg}>Please enter a valid password that meets the criteria</Box> : null}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary" style={styles.passwordFormat} paragraph>
                            1. At least one capital letter<br />
                            2. At least one lowercase letter<br />
                            3. At least one number<br />
                            4. At least one special character like $, @, #<br />
                            5. Minimum character length is 8 characters<br />
                            6. Password has to be different from previously used one
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <div style={styles.inputLable}>Confirm password <Box style={styles.required}>*</Box></div>
                            <TextField
                                data-test-id='confirmPassword'
                                error={this.state.inputErrors.confirmPasswordError}
                                name='confirm password'
                                placeholder="Confirm password"
                                type={this.state.showConfirmPassword ? 'text' : 'password'}
                                variant="outlined"
                                fullWidth margin="normal" required
                                style={styles.confirmpassInupt}
                                onChange={(e: any) => this.handleInput(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                data-test-id="showConfirmPassword"
                                                onClick={this.handleClickShowConfirmPassword}
                                            >
                                                {this.state.showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                            {this.state.inputErrors.confirmPasswordError ?
                                <Box sx={styles.errorMsg}>Password and confirm password should be same</Box> : null}
                        </div>
                    </Grid>

                    <Button
                        data-test-id="continue-btn"
                        variant={"outlined"}
                        disabled={this.state.inputErrors.confirmPasswordError ? true : false}
                        sx={{ textTransform: 'capitalize' }}
                        style={this.state.inputErrors.confirmPasswordError ? styles.continueBtnBlock : styles.continueBtn}
                        onClick={() =>  this.updateNewPassword({ password: this.state.password, confirmPassword: this.state.confirmPassword })} >
                        Continue
                    </Button>

                </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
                <StyledImgNewPassword src={this.state.sidePic} />
            </Grid>
        </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledImgNewPassword = styled('img')(({ theme }) => ({
    width: '100%',
    height: '100%',
    marginTop: '42px',
    marginBottom: '40px',
    [theme.breakpoints.up('md')]: {
        height: '73%',
        borderRadius: '64px 0px 0px 64px',
    },
}));

const styles = {
    rootNewPassword : {
        display: 'flex',
        alignItems: 'center',
        height: "26px",
        width: '71px',
        marginTop: '58px',
        marginLeft: '34px',
    },
    arrowNewPassword : {
        color: '#0F172A'
    },
    backBtnNewPassword : {
        fontSize: '18px',
        color: '#0F172A',
        fontFamily:"Inter"
    },
    formNewPassword : {
        heigth:'100%',
        display: 'flex',
        flexWrap:'wrap',
        marginLeft: {xs:'50px', md:'70px', lg:'105px'},
        marginTop: '200px',
        marginBottom: '20px',
        //backgroundColor: 'blue'
    },
    headImgBoxNewPassword:{
      with: '100%',
      heigth: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
    },
    headImgNewPassword : {
        margin:"0 auto",
        paddingLeft: '9px',
        height: '80px',
        width: '70px',
    },
    inputContainer : {
        marginTop:'80px',
        heigth: '100%',
        width: '100%',
        padding: {xs:'0 50px 0 50px', md:'0 70px 0 70px', lg:'0 105px 0 105px'},
    },
    inputLable : {
        fontSize: '14px',
        display: 'flex',
        fontWeight:'700',
        color:'#64748B',
        fontFamily:"Inter"
    },
    required : {
        color: 'red',
        marginLeft: '3px'
    },
    passwordInput : {
        height: '56px',
        borderRadius: '8px',
        fontSize:'16px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    confirmpassInupt : {
        height: '56px',
        borderRadius: '8px',
        fontSize:'16px',
        fontWeight:'400',
        fontFamily:"Inter"
    },
    errorMsg : {
        color:'#DC2626',
        fontSize:'12px',
        fontWeight:'400',
        marginBottom: '20px',
        fontFamily:"Inter"
    },
    continueBtn : {
        width: '100%',
        height: '56px',
        fontSize: '15px',
        color: 'white',
        background: 'linear-gradient(to right, #FCD34D, #D97706 )',
        marginTop: '8px',
        border: 'none',
        fontFamily:"Inter"
    },
    continueBtnBlock : {
        width: '100%',
        height: '56px',
        fontSize: '15px',
        background: '#F1F5F9',
        marginTop: '8px',
        border: 'none',
        color:'#64748B',
    },
    passwordFormat : {
        fontSize: '12px',
        fontWeight:"400",
        fontFamily:"Inter"
    },
};


// Customizable Area End
