// Customizable Area Start
import React from "react";

import {
  Typography,  
} from "@mui/material/";

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";
import Layout from "./Layout.web";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);

  }

  render(){
    return (
      <Layout id="" navigation={this.props.navigation}>
         <Typography sx={{color:'black'}}>Welcome to the Health Care System</Typography>
      </Layout>
    );
  }
}

// Customizable Area End
