// Customizable Area Start
import React from "react";
import { styled } from '@mui/material/styles';
import {Grid,Typography,Box} from '@mui/material';



// Customizable Area End

import ForgotPasswordController, {
    Props
} from "./ForgotPasswordController";

export default class ForgotPasswordSuccess extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
      return (
        // Customizable Area Start
        <Grid container>
            <Grid item xs={12} lg={6}>
                <Grid container style={{ height: '100%' }}>
                    <Grid item xs={12}>
                        <Box sx={styles.formForgotPassword}>
                            <Box sx={styles.inputContainer}>
                                <div style={{ width: '100%' }}>
                                    <div style={styles.headImgForgotPassword}>
                                        {<img src={this.state.logo} />}
                                    </div>
                                </div>
                                <Box style={styles.containerLabelHeading}>
                                    <Typography style={styles.mainHeading as React.CSSProperties}>
                                        Your password has been successfully resetted
                                    </Typography>
                                </Box>
                                <Box style={styles.containerLabelInputNumber}>
                                    <Typography style={styles.subHeading as React.CSSProperties}>
                                    Signing you in
                                    </Typography>
                                </Box>
                            </Box>
                        </Box >
                    </Grid>
                </Grid>
            </Grid>
            <Grid 
                item xs={12} lg={6}>
                <StyledImgForgotPassword 
                    src={this.state.sidePic} 
                />
            </Grid>
        </Grid>
        // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledImgForgotPassword = styled('img')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        borderRadius: '64px 0px 0px 64px',
        height: '73%',
    },
    height: '100%',
    width: '100%',
    marginBottom: '40px',
    marginTop: '42px',
}));
const styles = {
    containerLabelInputNumber:{
        display:'flex',
        width:'100%',
        justifyContent: 'center',
    },
    containerLabelHeading:{
        display:'flex',
        width:'100%',
        justifyContent: 'center',
        margin:"20px 0"
    },
    formForgotPassword : {
        heigth:'100%',
        display: 'flex',
        flexWrap:'wrap',
        marginLeft: {xs:'50px', md:'70px', lg:'105px'},
        marginTop: '200px',
        marginBottom: '20px',
    },
    headImgForgotPassword : {
        margin:"0 auto",
        height: '80px',
        width: '70px',
    },
    inputContainer : {
        heigth: '100%',
        width: "50%",
        margin: "0 auto",
        padding: {xs:'0 50px 0 0px', md:'0 70px 0 0px', lg:'0 105px 0 0px'},
    },
    mainHeading:{
        fontFamily: "Inter",
        fontSize: "30px",
        fontWeight: "400",
        lineHeight: "40px",
        textAlign: "center",
        color:"#059669"
    },
    subHeading:{
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        textAlign: "center",
    }
};


// Customizable Area End
