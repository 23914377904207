import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, headLogo, sidePic } from "./assets";
import  {setStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  head: string;
  sidePic: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  confirmPassword: string;
  isOpenTC:boolean;
  isOpenPolicy:boolean;
  TermsAndCondList: { id: number, description: string, created_at: string, title: string }[];
  PrivacyPolicyList: { id: number, description: string, created_at: string, title:string }[];
  inputErrors: {
    firstNameError: boolean,
    lastNameError: boolean,
    emailError: boolean,
    phoneError: boolean,
    passwordError: boolean,
    confirmPasswordError: boolean
  },
  errorMessages: {
    passwordErrorMessage: string;
    confirmPasswordErrorMessage: string;
  };
  phoneNumberAlreadyTakenError:string
  emailInvalidError:string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  signupAccountApiCallId: any;
  validationApiCallId: string = "";
  getTermsAndCondApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  scrollPosition: number = 0;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      head: headLogo.default,
      sidePic: sidePic.default,
      showPassword: false,
      showConfirmPassword: false,
      confirmPassword: '',
      isOpenTC: false,
      isOpenPolicy: false,
      TermsAndCondList: [],
      PrivacyPolicyList: [],
      inputErrors: {
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        phoneError: false,
        passwordError: false,
        confirmPasswordError: false
      },
      errorMessages: {
        passwordErrorMessage: "",
        confirmPasswordErrorMessage: "",
      },
      phoneNumberAlreadyTakenError:"",
      emailInvalidError:""
      
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
       if (apiRequestCallId === this.signupAccountApiCallId) {
          this.signupApi(responseJson);
        }
      }
      // receive term condition response
      if (this.getTermsAndCondApiCallId === apiRequestCallId && responseJson) {
          this.setState({
            TermsAndCondList: responseJson.data
          })
      }

      //receive privacy policy response
      if (this.getPrivacyPolicyApiCallId === apiRequestCallId && responseJson) {
          this.setState({
            PrivacyPolicyList: responseJson.data
          })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handleGetTermsAndConditions();
    this.handleGetPrivacyPolicy();
    window.scrollTo(0, 0);
  }
  
  signupApi(responseJson: any) {
    if (!responseJson.errors || responseJson.errors.length === 0) {
      setStorageData("isFrom", "SignUp");
      setStorageData("token", responseJson.token.token);
      this.phonenumberVerification('OTPInputAuth');
    } else {
      let phoneNumberError = '';
      let emailError = '';
  
      responseJson.errors.forEach((error: any) => {
        if (error.full_phone_number) {
          phoneNumberError = error.full_phone_number;
        }
        if (error.account) {
          emailError = error.account;  // Handle email errors under 'account'
        }
      });
  
      this.setState({
        phoneNumberAlreadyTakenError: phoneNumberError,
        emailInvalidError: emailError
      });
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleconfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleOpenTermCondition = () => {
    // alert('hello');
    this.scrollPosition = window.scrollY;
    this.setState({ isOpenTC: true });
  }

  handleCloseTermCondition = () => {
    this.setState({ isOpenTC: false }, () => {
      setTimeout(() => {
        window.scrollTo(0, this.scrollPosition);
      }, 50); 
    });
  }
   
  handleOpenPolicy = () => {
    this.scrollPosition = window.scrollY;
    this.setState({ isOpenPolicy: true });
  }

  handleClosePolicy = () => {
    this.setState({ isOpenPolicy: false }, () => {
      setTimeout(() => {
        window.scrollTo(0, this.scrollPosition)
      }, 50)
    });
  }

  handleInput = (e: any) => {
    let { name, value } = e.target;

    switch(name) {
      case 'first name':
        this.handleNames(name,value);
        break;
      case 'last name':
        this.handleNames(name,value);
        break;
      case 'email address':
        this.handleNames(name,value);
        break;
      case 'phone number':
        this.handleValues(name,value);
        break;
      case 'password':
        this.handleValues(name,value);
        break;
      case 'confirm password':
        this.handleValues(name,value);
        break;
      default:
        break;
    }
  }

  handleNames = (name:string,value:string) => {
    switch(name) {
      case 'first name':
        if (/^[A-Za-z][a-zA-Z'-]*$/.test(value) && value !== "") {
          this.setState({ firstName: value });
          this.setState({ inputErrors:{...this.state.inputErrors,firstNameError:false} })
        }
          this.setState({ firstName: value });
        break;
      case 'last name':
        if (value !== '' && /^[A-Za-z][a-zA-Z'-]*$/.test(value)) {
          this.setState({ lastName: value });
          this.setState({ inputErrors:{...this.state.inputErrors,lastNameError:false} })
        }
          this.setState({ lastName: value });
        break;
      case 'email address':
        this.setState({emailInvalidError:""})
        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) && value !== '') {
          this.setState({ email: value });
          this.setState({ inputErrors:{...this.state.inputErrors,emailError:false} })
        }
          this.setState({ email: value });
        break;
      default:
          break;
    }
  }

  handleValues = (name:string, value:string) => {
    const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    switch(name) {
      case 'phone number':
        this.setState({phoneNumberAlreadyTakenError:""})
        if (/^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/.test(value) || value === '') {
          this.setState({ inputErrors:{...this.state.inputErrors,phoneError:false}})
        }
        this.setState({ phone: `+1${value}` });
        break;
      case 'password':
        this.setState({ password: value }, () => {
          const { password, confirmPassword } = this.state;
          const passwordError = !(passwordCriteria.test(password)) || password === '';
          const confirmPasswordError = password !== confirmPassword;
          
          this.setState({
            inputErrors: {
              ...this.state.inputErrors,
              passwordError,
              confirmPasswordError
            },
            errorMessages: {
              ...this.state.errorMessages,
              passwordErrorMessage: passwordError ? configJSON.errorPasswordMessage : '',
              confirmPasswordErrorMessage: confirmPasswordError ? configJSON.errorConfirmPasswordMessage : ''
            }
          });
        });
        break;
      case 'confirm password':
        this.setState({ confirmPassword: value }, () => {
          const { password, confirmPassword } = this.state;
          const confirmPasswordError = password !== confirmPassword;
          
          this.setState({
            inputErrors: {
              ...this.state.inputErrors,
              confirmPasswordError
            },
            errorMessages: {
              ...this.state.errorMessages,
              confirmPasswordErrorMessage: confirmPasswordError ? configJSON.errorConfirmPasswordMessage : ''
            }
          });
        });
        break;
      default:
        break;
    }
  }
  

  handleSignUp =async () => {
    let errorObj = {
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phoneError: false,
      passwordError: false,
      confirmPasswordError: false
    };
    let errorMessages = {
      passwordErrorMessage: "",
      confirmPasswordErrorMessage: ""
    };
    if (!/^[A-Za-z][a-zA-Z'-]*$/.test(this.state.firstName) || this.state.firstName == "" || this.state.firstName.length>64) {
      errorObj.firstNameError = true;
    }
    if (!/^[A-Za-z][a-zA-Z'-]*$/.test(this.state.lastName) || this.state.lastName == "" || this.state.lastName.length>64) {
      errorObj.lastNameError = true;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.email) || this.state.email === "" || this.state.email.length>64) {
      errorObj.emailError = true;
    }
    if (!/^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/.test(this.state.phone) || this.state.phone === "") {
      errorObj.phoneError = true;
    }
    const passwordCriteria = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    if (!passwordCriteria.test(this.state.password) || this.state.password === "") {
      errorObj.passwordError = true;
      errorMessages.passwordErrorMessage = configJSON.errorPasswordMessage;
    } 
    if (this.state.password !== this.state.confirmPassword || this.state.confirmPassword === "") {
      errorObj.confirmPasswordError = true;
      errorMessages.confirmPasswordErrorMessage = configJSON.errorConfirmPasswordMessage;
    }
    this.setState({ inputErrors: { ...errorObj }, errorMessages })
    const anyErrors = this.anyErrors(errorObj);
    if (anyErrors) {
      console.log("return zero");
      return 0;
    } else {
      await setStorageData("phoneNumber",this.state.phone);
      await setStorageData("emailAddress",this.state.email);
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail
      };
      const attrs = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        full_phone_number:  this.state.countryCodeSelected + this.state.phone
      };
      const data = {
        type: "email_account",
        is_salesforce_exist: false,
        attributes: attrs
      };
      const httpBody = {
        data: data,
        //token: this.state.otpAuthToken
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.signupAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.signupAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleGetTermsAndConditions = () => {
    const header = {};

    const termConditionMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    termConditionMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndCondEndPoint
    );

    termConditionMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    termConditionMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    this.getTermsAndCondApiCallId = termConditionMes.messageId;

    runEngine.sendMessage(termConditionMes.id, termConditionMes);
  }

  handleGetPrivacyPolicy = () => {
    const header = {};

    const privacyPolicyMes = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    privacyPolicyMes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyEndPoint
    );

    privacyPolicyMes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    privacyPolicyMes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    this.getPrivacyPolicyApiCallId = privacyPolicyMes.messageId;

    runEngine.sendMessage(privacyPolicyMes.id, privacyPolicyMes);
  }

  anyErrors = (errorObj: any) => {
    for (let arr of Object.entries(errorObj)) {
      let val = arr[1]
      if (val === true) {
        return true;
      }
    }
  }

  phonenumberVerification = (name:string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      name
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }



  // Customizable Area End
}
